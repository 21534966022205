import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Heading, Text, Styled } from "theme-ui"

const Page = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="Impressum"
      description="Das Impressum unserer Website"
    />
    <Heading as="h1" variant="siteTitle">
      Impressum
    </Heading>
    <Text variant="subheading">Angaben gemäß § 5 TMG</Text>
    <Text>
      Sandra Uschtrin
      <br />
      Uschtrin Verlag
      <br />
      Gänsbrunnenweg 1a<br />
      D-85652 Pliening<br />
      <br />
      <br />
      Postanschrift:
      <br />
      Sandra Uschtrin
      <br />
      Uschtrin Verlag
      <br />
      Cincinnatistr. 9
      <br />
      D-81549 München
    </Text>

    <Text variant="subheading">Kontakt</Text>
    <Text>
      Telefon:{" "}
      <Styled.a href="tel:+49 89 / 21528793">+49 89 / 21528793</Styled.a>
      <br />
      E-Mail:{" "}
      <Styled.a href="mailto:info@uschtrin.de">info@uschtrin.de</Styled.a>
    </Text>

    <Text variant="subheading">Umsatzsteuer</Text>
    <Text>
      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
      <br />
      DE189820836
    </Text>

    <Text variant="subheading">
      Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
    </Text>
    <Text>
      Sandra Uschtrin
      <br />
      Gänsbrunnenweg 1a<br />
      D-85652 Pliening
    </Text>

    <Text variant="subheading">Streitschlichtung</Text>
    <Text>
      Die Europäische Kommission stellt eine Plattform zur Online-
      Streitbeilegung (OS) bereit:{" "}
      <Styled.a href="https://ec.europa.eu/consumers/odr">
        https://ec.europa.eu/consumers/odr
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />
      .
      <br />
      Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </Text>

    <Text>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </Text>
  </Layout>
)

export default Page
